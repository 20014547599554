<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        ref="addNewOrderForm"
        class="add-new-order-form"
        @submit.prevent="submitNewOrderForm"
      >
        <v-row>
          <v-col
            cols="6"
            class="pt-0 mt-n2"
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <ChooseClientAddressPartial
                  ref="chooseClientAddressPartial"
                  :addresses="addresses"
                  :order-data="order"
                  :course-type="course.courseType"
                  :show-address-form="showAddressForm"
                  :specific-client="specificClient"
                  :distance="straightLineDistance"
                  :is-adding-client-to-middleman-mode="isAddingClientToMiddlemanMode"
                  :disable-suggested-order-price="isFormDisabled"
                  @update="updateFormValue"
                  @addAddress="changeAddressFormStatus(true)"
                  @editAddress="changeAddressFormStatus(true, true)"
                  @showNewMiddlemanClientForm="isAddingClientToMiddlemanMode = true"
                />
              </v-col>

              <template v-if="showAddressForm">
                <v-col
                  cols="12"
                  class="pt-0 d-flex"
                >
                  <div class="form__section-title">
                    {{ chosenAddress ? 'Edytuj' : 'Dodaj' }} lokalizację
                  </div>
                  <v-btn
                    v-if="savedClientData"
                    icon
                    x-small
                    color="primary"
                    class="ml-2 mt-1"
                    title="Skopiuj dane klienta"
                    @click="$refs.addressForm.copyClientData(savedClientData)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  class="p-0"
                >
                  <AddressForm
                    ref="addressForm"
                    actions
                    :editing-address="chosenAddress"
                    :payment-type-visible="false"
                    :forced-department="department.id"
                    :edit-address-products="true"
                    @close="changeAddressFormStatus(false)"
                    @addNewAddress="addAddress($event, true)"
                    @editAddress="updateAddress"
                  />
                </v-col>
              </template>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="pt-0 mt-4"
          >
            <v-row>
              <v-col cols="12">
                <NewCoursePartial
                  :disabled="isFormDisabled"
                  :course-data="course"
                  :order-data="order"
                  is-simple-order-type
                  @update="updateFormValue"
                />
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <NewPaymentPartial
                  ref="payment"
                  :disabled="isFormDisabled"
                  :payment-data="payment"
                  :course-type="course.courseType"
                  :client-type="clientType"
                  :aggregate-amount="order.aggregateAmount"
                  :location-discount="locationDiscount"
                  :distance="straightLineDistance"
                  @update="updateFormValue"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="base-hover"
        :loading="isProcessing"
        :disabled="isSubmitDisabled"
        @click="submitNewOrderForm()"
      >
        Stwórz zlecenie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { parseAsBasicUnit, getGrossValue } from '../../utils'
import updateFormValue from '../../mixins/updateFormValue.vue'
import orderFormMixin from '../../mixins/orderFormMixin.vue'
import api from '../../api/v1'
import { NewOrder } from '../../models'

export default {
  mixins: [
    updateFormValue,
    orderFormMixin, // changeAddressFormStatus, fetchClientAddresses, addAddress, updateAddress, addresses
    // order, course, payment, showAddressForm, , chosenAddress, locationDiscount, populateAddressData,
    // department, isProcessing, straightLineDistance, closeDialog, showSnackbar, clientType
    // watch: 'order.addressId'
  ],
  props: {
    specificClient: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    updatePayment: true,
    isPriceCalculationAllowed: true,
    isClientFormOpen: false,
    savedClientData: null,
    isAddingClientToMiddlemanMode: false
  }),
  computed: {
    ...mapState({
      productsList: state => state.orders.newOrderProducts,
    }),
    priceParams () {
      const { debrisTypeId, containerTypeId } = this.order
      return {
        departmentId: this.department.id,
        debrisTypeId,
        containerTypeId,
        aggregateAmount: this.course.courseType === 'Transport' ? 1000 : null // for now, asked BE to remove this parameter
      }
    },
    isFormDisabled () {
      return !this.order.addressId || !this.order.clientId
    },
    isSubmitDisabled () {
      return this.isFormDisabled || this.showAddressForm || this.isClientFormOpen || !this.payment.debrisNetValue
    }
  },
  watch: {
    'order.clientId' (id) {
      if (id && !this.order.middlemanId) this.fetchClientAddresses() // if client is chosen as middleman, and middlemanId takes his id, next clientIds doesn't trigger fetchClientAddresses
    },
    priceParams: {
      deep: true,
      handler () {
        const { debrisTypeId, containerTypeId } = this.order
        if (debrisTypeId && containerTypeId && this.isPriceCalculationAllowed) {
          const matchingProduct = this.productsList.find(product => {
            return product.debrisType?.id === debrisTypeId && product.containerType?.id === containerTypeId
          })
          if (matchingProduct) {
            this.payment.debrisNetValue = matchingProduct.netValue / 100
            this.showSnackbar({
              message: ['Zaktualizowano cenę na podstawie produktu.']
            })
          } else {
            this.calculatePrice()
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      addNewOrder (dispatch, payload) {
        const tableName = this.specificClient ? 'clientOrders' : 'orders'
        return dispatch(`${tableName}/addNewItem`, payload)
      },
    }),
    calculatePrice () {
      const isTransport = this.course.courseType === 'Transport'
      const params = {
        ...this.priceParams,
        pricingListType: isTransport ? 'aggregate' : 'debris',
      }
      if (this.course.courseType === 'Podstawienie') delete params.aggregateAmount

      api.calculatePrice(params)
        .then((resp) => {
          const netValue = resp.data.debrisNetValue / 100
          this.payment.origin = 'C' // represents 'Cennik' as price origin
          this.payment.debrisNetValue = netValue
          this.payment.debrisGrossValue = getGrossValue(netValue, this.payment.vatPercentage)
        })
    },
    submitNewOrderForm () {
      if (this.$refs.addNewOrderForm.validate()) {
        const payment = { ...this.payment }
        payment.debrisNetValue = parseAsBasicUnit(payment.debrisNetValue)
        payment.debrisGrossValue = parseAsBasicUnit(payment.debrisGrossValue)
        payment.transportNetValue = parseAsBasicUnit(payment.transportNetValue)
        payment.transportGrossValue = parseAsBasicUnit(payment.transportGrossValue)
        payment.discount = parseAsBasicUnit(payment.discount)
        const params = {
          ...this.order,
          course: this.course,
          payment
        }
        if (this.course.courseType === 'Podstawienie') {
          delete params.aggregateAmount
          delete params.payment.transportNetValue
          delete params.payment.transportGrossValue
        }
        if (this.course.courseType === 'Transport') {
          delete params.payment.discount
          delete params.payment.discountJustification
        }
        if (this.$route.query?.callEntryId) {
          params.course.callEntryId = Number(this.$route.query.callEntryId)
        }

        this.addNewOrder({ params, endpoint: 'orders' })
          .then(() => {
            const { order, course, payment } = new NewOrder()
            this.order = order
            this.course = course
            this.payment = payment

            this.closeDialog()
          })
      } else {
        this.showSnackbar({
          type: 'error',
          message: ['Nie wszystkie pola wypełniono poprawnie']
        })
      }
    }
  }
}
</script>
